import type { AppState } from "@appsmith/reducers";
import { getPlugins } from "@appsmith/selectors/entitiesSelector";
import type { PluginType } from "entities/Action";
import { getActionConfig } from "pages/Editor/Explorer/Actions/helpers";
import history from "utils/history";
import type { NavigationMethod } from "utils/history";

const getPlugin = (state: AppState, pluginId: string) => {
  const plugins = getPlugins(state);
  return plugins.find((plugin) => plugin?.id === pluginId);
};

export const navigateToAction = (
  id: string,
  pageId: string,
  pluginId: string,
  pluginType: PluginType,
  state: AppState,
  navigationMethod?: NavigationMethod,
) => {
  const actionConfig = getActionConfig(pluginType);
  const plugin = getPlugin(state, pluginId);

  const url = actionConfig?.getURL(pageId, id, pluginType, plugin);
  url && history.push(url, { invokedBy: navigationMethod });
};
