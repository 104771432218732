import { useCallback } from "react";
import { useStore } from "react-redux";
import { keyBy } from "lodash";

import { ENTITY_TYPE } from "entities/DataTree/dataTreeFactory";
import {
  getDataTree,
  getEvaluationInverseDependencyMap,
} from "selectors/dataTreeSelectors";
import { useNavigateToWidget } from "pages/Editor/Explorer/Widgets/useNavigateToWidget";
import { NavigationMethod } from "utils/history";
import type {
  ActionEntity,
  JSActionEntity,
  WidgetEntity,
} from "@appsmith/entities/DataTree/types";
import type { ActionData } from "@appsmith/reducers/entityReducers/actionsReducer";
import type { AppState } from "@appsmith/reducers";
import type { JSCollectionData } from "@appsmith/reducers/entityReducers/jsActionsReducer";

import { navigateToAction, navigateToJsEntity } from "spread/navigateToEntity";

import {
  getJsFunctionIcon,
  getJsObjectIcon,
  getWidgetIcon,
  useGetActionIconByActionId,
} from "./helpers/entitiesIcons";
import { findUsage, type UsageCases } from "./helpers/findUsage";
import type { EntityParamUsageInfo, EntityUsageItem } from "./types";

interface Dictionary<T> {
  [index: string]: T;
}

const getActionFieldName = (usedInParam: string): string | undefined => {
  const fieldMap: Record<string, string> = {
    "config.body": "query",
    "config.pluginSpecifiedTemplates[1].value": "query variable",
  };
  return fieldMap[usedInParam];
};

/**
 * Hook to get the usage info of an entity.
 * It returns a list of params and their usages, also methods to navigate to the usage.
 * It is not triggering re-renders on store changes, geting data from the store only when called.
 */
export const useEntityUsageInfo = () => {
  const store = useStore();

  const getUsageList = useCallback((entityName: string) => {
    const state = store.getState();
    const tree = getDataTree(state);
    const dependencyMap = getEvaluationInverseDependencyMap(state);
    const usage = findUsage(dependencyMap, tree, entityName);
    return usage;
  }, []);

  const { navigateToWidget } = useNavigateToWidget();
  const getActionIconByActionId = useGetActionIconByActionId();

  const getNavigatableItemForAction = useCallback(
    (
      entity: ActionEntity,
      actionsById: Dictionary<ActionData>,
      usedInParam: string,
      state: AppState,
    ): EntityUsageItem => {
      const actionId = entity.actionId;
      const action = actionsById[actionId];
      const pageId = action.config.pageId;
      const pluginId = action.config.pluginId;
      const pluginType = action.config.pluginType;

      const field = getActionFieldName(usedInParam);
      const titleSuffix = field ? ` (${field})` : "";
      const title = `${action.config.name}${titleSuffix}`;

      const navigateToUsage = () => {
        navigateToAction(
          actionId,
          pageId,
          pluginId,
          pluginType,
          state,
          NavigationMethod.EntityUsageInfo,
        );
      };

      const icon = getActionIconByActionId(actionId, pluginId);

      return { title, icon, navigateToUsage };
    },
    [getActionIconByActionId],
  );

  const getNavigatableItemForJsAction = useCallback(
    (
      entity: JSActionEntity,
      jsActionsById: Dictionary<JSCollectionData>,
      usedInParam: string,
    ): EntityUsageItem => {
      const jsActionId = entity.actionId;
      const targetJsCollection = jsActionsById[jsActionId];

      const collectionName = targetJsCollection.config.name;
      const pageId = targetJsCollection.config.pageId;
      const collectionId = targetJsCollection.config.id;
      const navigateToCollection = (functionName?: string) => {
        navigateToJsEntity(
          pageId,
          collectionId,
          functionName,
          NavigationMethod.EntityUsageInfo,
        );
      };

      const collectionResult = {
        title: collectionName,
        icon: getJsObjectIcon(),
        navigateToUsage: () => navigateToCollection(),
      };

      // If there is no param, return the whole collection
      if (!usedInParam) {
        return collectionResult;
      }

      const jsFunction = targetJsCollection.config.actions.find(
        (action) => action.name === usedInParam,
      );

      // If the param is a function, return the function
      if (jsFunction) {
        const title = `${targetJsCollection.config.name}.${jsFunction.name}`;
        return {
          title,
          icon: getJsFunctionIcon(),
          navigateToUsage: () => navigateToCollection(jsFunction.name),
        };
      }

      const isVariable = targetJsCollection.config.variables?.some(
        (variable) => variable.name === usedInParam,
      );

      // If the param is a variable, return collection with variableName
      if (isVariable) {
        const title = `${targetJsCollection.config.name}.${usedInParam}`;
        return {
          title,
          icon: getJsObjectIcon(),
          navigateToUsage: () => navigateToCollection(usedInParam),
        };
      }

      // default
      return collectionResult;
    },
    [],
  );

  const getNavigatableItemForWidget = useCallback(
    (
      entity: WidgetEntity,
      usedInParam: string,
      currentPageId,
    ): EntityUsageItem => {
      const widgetId = entity.widgetId;
      const widgetType = entity.type;
      const title = `${entity.widgetName}.${usedInParam}`;
      const icon = getWidgetIcon(widgetType);
      const navigateToUsage = () => {
        navigateToWidget(
          widgetId,
          widgetType,
          currentPageId,
          NavigationMethod.EntityUsageInfo,
        );
      };

      return { title, icon, navigateToUsage };
    },
    [navigateToWidget],
  );

  const getListOfNavigatableItems = useCallback(
    (usageList: UsageCases): EntityParamUsageInfo[] => {
      const state = store.getState();
      const evaluationsTree = getDataTree(state);

      const entities = state.entities;

      const jsActions = entities.jsActions;
      const jsActionsById = keyBy(jsActions, "config.id");

      const actions = entities.actions;
      const actionsById = keyBy(actions, "config.id");

      const currentPageId = state.entities.pageList.currentPageId;

      return Object.entries(usageList).map(([paramName, usages]) => {
        const entityUsageItems = usages.reduce<EntityUsageItem[]>(
          (acc, usage) => {
            const entity = evaluationsTree[usage.entityName];
            const entityType = entity.ENTITY_TYPE;
            const usedInParam = usage.paramName;

            if (entityType === ENTITY_TYPE.ACTION) {
              const item = getNavigatableItemForAction(
                entity,
                actionsById,
                usedInParam,
                state,
              );
              acc.push(item);
              return acc;
            }

            if (entityType === ENTITY_TYPE.JSACTION) {
              const item = getNavigatableItemForJsAction(
                entity,
                jsActionsById,
                usedInParam,
              );
              acc.push(item);
              return acc;
            }

            if (entityType === ENTITY_TYPE.WIDGET) {
              const item = getNavigatableItemForWidget(
                entity,
                usedInParam,
                currentPageId,
              );
              acc.push(item);
              return acc;
            }

            return acc;
          },
          [],
        );

        return { paramName, usages: entityUsageItems };
      });
    },
    [
      getNavigatableItemForAction,
      getNavigatableItemForJsAction,
      getNavigatableItemForWidget,
    ],
  );

  const getEntityUsageInfo = useCallback((entityName: string | undefined) => {
    if (!entityName) return [];
    const usageList = getUsageList(entityName);
    return getListOfNavigatableItems(usageList);
  }, []);

  return getEntityUsageInfo;
};
