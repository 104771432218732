import React from "react";
import styled from "styled-components";

import type { EntityTypeValue } from "entities/DataTree/dataTreeFactory";

import type { EntityParamUsageInfo, OnItemClickFn } from "../types";
import { EntityUsageEmptyState } from "./EmptyState";
import { EntityUsageCluster } from "./Cluster";

interface EntityUsageInfoResultsProps {
  data: EntityParamUsageInfo[];
  onItemClick: OnItemClickFn;
  entityType: EntityTypeValue;
  entityName: string;
}

const ResultsContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  padding: 0 ${(props) => `${props.theme.spaces[1]}px`};
`;

export const EntityUsageInfoResults = ({
  data,
  entityName,
  entityType,
  onItemClick,
}: EntityUsageInfoResultsProps) => (
  <ResultsContainer>
    {data.length > 0 ? (
      data.map((dataItem, index) => (
        <EntityUsageCluster
          data={dataItem}
          entityName={entityName}
          isFirst={index === 0}
          key={dataItem.paramName}
          onItemClick={onItemClick}
        />
      ))
    ) : (
      <EntityUsageEmptyState entityType={entityType} />
    )}
  </ResultsContainer>
);
