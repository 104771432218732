import React from "react";
import styled, { css } from "styled-components";
import { Text } from "design-system";
import { getTypographyByKey } from "design-system-old";

const overflowCSS = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spaces[3]}px;
  transition: 0.3s background-color ease;
  border-radius: var(--ads-v2-border-radius);

  margin-bottom: 2px;
  gap: 8px;
  height: 36px;

  &:hover {
    background-color: var(--ads-v2-color-bg-subtle);
  }

  ${getTypographyByKey("p1")};
`;

const ItemTitle = styled(Text)`
  max-width: 300px;
  ${overflowCSS}
`;

interface EntityUsageItemProps {
  icon: React.ReactNode;
  title: string;
  onClick: (event?: React.MouseEvent) => void;
}

export const EntityUsageItem = ({
  icon,
  onClick,
  title,
}: EntityUsageItemProps) => (
  <ItemContainer onClick={onClick}>
    {icon}
    <ItemTitle>{title}</ItemTitle>
  </ItemContainer>
);
