import type { EvaluatedTreeState } from "reducers/evaluationReducers/treeReducer";
import type { DependencyMap } from "utils/DynamicBindingUtils";

// Universal interface for describing usage
interface UsageCase {
  entityName: string;
  paramName: string;
}

// Universal interface for describing usage with parameters
export interface UsageCases {
  [paramName: string]: UsageCase[];
}

/**
 * Filters and returns dependencies from the dependencyMap for the specified entity
 * @param dependencyMap - Dependency map
 * @param targetEntityName - Name of the target entity
 * @returns An object containing information about usage of dependencies
 */
function getDependenciesFromMap(
  dependencyMap: DependencyMap,
  targetEntityName: string,
): UsageCases {
  const usage: UsageCases = {};

  Object.entries(dependencyMap).forEach(([key, value]) => {
    const [entityName, func] = key.split(".");

    if (entityName === targetEntityName && func) {
      const filteredValues = value.filter(
        (item) => item.split(".")[0] !== entityName,
      );

      if (filteredValues.length > 0) {
        usage[func] = filteredValues.map((filteredValue) => {
          const [depEntityName, paramName] = filteredValue.split(/\.(.+)/); // Split by first dot
          return { entityName: depEntityName, paramName };
        });
      }
    }
  });

  return usage;
}

/**
 * Iterates over evaluations.tree and returns function calls and variable/constant usage for the specified entity
 * @param tree - Tree object
 * @param targetEntityName - Name of the target entity
 * @returns An object containing information about function call and variable/constant usage
 */
function getEntityUsagesInWidgets(
  tree: EvaluatedTreeState,
  targetEntityName: string,
): UsageCases {
  const usage: UsageCases = {};
  const ENTITY_USAGE_REGEX = new RegExp(
    `(${targetEntityName})\\.([a-zA-Z0-9_]+)(\\([^)]*\\))?`,
    "g",
  );

  Object.entries(tree).forEach(([, obj]) => {
    if (obj.ENTITY_TYPE !== "WIDGET") return;

    const widgetName = obj.widgetName || "UnknownWidget";

    // Iterate over each property in the object
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && typeof obj[prop] === "string") {
        // Find function invocations and variable/constant usage
        const matches = obj[prop].matchAll(ENTITY_USAGE_REGEX);
        for (const match of matches) {
          const [, entityName, identifier] = match;
          if (entityName === targetEntityName && identifier) {
            if (!usage[identifier]) {
              usage[identifier] = [];
            }
            usage[identifier].push({ entityName: widgetName, paramName: prop });
          }
        }
      }
    }
  });

  return usage;
}

/**
 * Function to find the usage of the specified entity in the dependencyMap and tree
 * @param dependencyMap - Dependency map (state.evaluations.dependencies.inverseDependencyMap)
 * @param tree - evaluations.tree object
 * @param targetEntityName - Name of the target entity
 * @returns An object containing information about usage of the target entity
 */
export function findUsage(
  dependencyMap: DependencyMap,
  tree: EvaluatedTreeState,
  targetEntityName: string,
): UsageCases {
  const usageCasesFromMap = getDependenciesFromMap(
    dependencyMap,
    targetEntityName,
  );
  const entityUsagesInWidgets = getEntityUsagesInWidgets(
    tree,
    targetEntityName,
  );

  // Combine results from dependency map and tree
  const combinedUsage: UsageCases = { ...usageCasesFromMap };

  for (const identifier in entityUsagesInWidgets) {
    if (entityUsagesInWidgets.hasOwnProperty(identifier)) {
      if (!combinedUsage[identifier]) {
        combinedUsage[identifier] = [];
      }
      combinedUsage[identifier] = combinedUsage[identifier].concat(
        entityUsagesInWidgets[identifier],
      );
    }
  }

  return combinedUsage;
}
