/**
 * Inspired by `src/pages/Editor/Explorer/Entity/EntityProperties.tsx
 */

import React, { useEffect } from "react";
import classNames from "classnames";
import styled from "styled-components";

import { EntityClassNames } from "pages/Editor/Explorer/Entity";

import useAdjustPopoverPosition from "./useAdjustPopoverPosition";

const BINDING_CONTAINER_MAX_HEIGHT = 300;

const EntityInfoContainer = styled.div`
  min-width: 220px;
  max-width: 400px;
  max-height: ${BINDING_CONTAINER_MAX_HEIGHT}px;
  overflow-y: hidden;
  border-radius: var(--ads-v2-border-radius);
  border: 1px solid var(--ads-v2-color-border-muted);
  box-shadow: var(--ads-v2-shadow-popovers);
`;

interface PopoverProps {
  show: boolean;
  entityId: string;
  children: React.ReactNode;
  handleCloseClickEvent: (e: MouseEvent) => void;
}

export const Popover = ({
  children,
  entityId,
  handleCloseClickEvent,
  show,
}: PopoverProps) => {
  const popoverRef = useAdjustPopoverPosition(
    entityId,
    BINDING_CONTAINER_MAX_HEIGHT,
  );

  const handleOutsideClick = (e: MouseEvent) => {
    e.stopPropagation();
    const entityPropertiesContainer = popoverRef.current;
    const paths = e.composedPath();
    if (
      !!entityPropertiesContainer &&
      !paths?.includes(entityPropertiesContainer)
    )
      handleCloseClickEvent(e);
  };

  /**
   * Close the panel when clicked outside
   */
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [show]);

  return (
    <EntityInfoContainer
      className={classNames({
        "absolute bp3-popover overflow-y-auto overflow-x-hidden bg-white pb-2 flex flex-col justify-center z-10 delay-150 transition-all":
          true,
        [EntityClassNames.CONTEXT_MENU_CONTENT]: true,
        "-left-100": !show,
      })}
      ref={popoverRef}
    >
      <div className="h-auto overflow-y-auto overflow-x-hidden relative">
        {children}
      </div>
    </EntityInfoContainer>
  );
};
