import React from "react";
import { Flex, Text, Icon } from "design-system";
import type { EntityTypeValue } from "entities/DataTree/dataTreeFactory";

interface EmptyStateViewProps {
  icon: string;
  description: string;
}

const EmptyStateView = ({ description, icon }: EmptyStateViewProps) => {
  return (
    <Flex
      alignItems={"center"}
      flexDirection="column"
      gap="spaces-4"
      justifyContent={"center"}
      px="spaces-3"
      py="spaces-7"
    >
      <Flex
        alignItems={"center"}
        backgroundColor={"var(--ads-v2-color-bg-subtle)"}
        borderRadius={"var(--ads-v2-border-radius)"}
        height={"40px"}
        justifyContent={"center"}
        padding={"spaces-3"}
        width={"40px"}
      >
        <Icon name={icon} size={"lg"} />
      </Flex>
      <Text
        className={"text-center"}
        color={"var(--ads-v2-color-fg)"}
        kind={"heading-xs"}
      >
        {description}
      </Text>
    </Flex>
  );
};

const getEmptyStateContent = (entityType: EntityTypeValue) => {
  if (entityType === "ACTION") {
    return {
      description: "No usage found for this API",
      icon: "queries-v3",
    };
  }

  if (entityType === "JSACTION") {
    return {
      description: "No usage found for this JS Object",
      icon: "js-square-v3",
    };
  }

  if (entityType === "WIDGET") {
    return {
      description: "No usage found for this Widget",
      icon: "widgets-v3",
    };
  }

  return null;
};

interface EmptyStateProps {
  entityType: EntityTypeValue;
}

export const EntityUsageEmptyState = ({ entityType }: EmptyStateProps) => {
  const content = getEmptyStateContent(entityType);

  if (!content) {
    return null;
  }

  return (
    <EmptyStateView description={content.description} icon={content.icon} />
  );
};
