/**
 * Inspired by `src/pages/Editor/Explorer/Entity/EntityProperties.tsx
 */

import React from "react";
import { Button } from "design-system";

const HEADER_TEXT_PREFFIX = "Usage of ";

interface HeaderProps {
  entityName: string;
  onClose: (event: React.MouseEvent) => void;
}

export const Header = ({ entityName, onClose }: HeaderProps) => (
  <div className="sticky top-0 text-sm px-3 z-5 pt-2 pb-1 font-medium flex flex-row items-center justify-between w-[100%] bg-white">
    {`${HEADER_TEXT_PREFFIX}${entityName}`}
    <Button
      className="t--entity-property-close"
      isIconButton
      kind="tertiary"
      onClick={onClose}
      size="sm"
      startIcon="close-control"
    />
  </div>
);
