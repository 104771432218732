import React from "react";
import styled, { css } from "styled-components";
import { Text } from "design-system";

import type { EntityParamUsageInfo, OnItemClickFn } from "../types";
import { EntityUsageItem } from "./Item";

const clusterContainerTopSeparator = css`
  border-top: 1px solid var(--ads-v2-colors-content-surface-default-border);
`;
const ClusterContainer = styled.div<{ topSeparator?: boolean }>`
  ${({ topSeparator }) => topSeparator && clusterContainerTopSeparator}
  width: 100%;
`;

const ClusterTitle = styled(Text)`
  padding: 0 ${(props) => `${props.theme.spaces[3]}px`};
`;
ClusterTitle.defaultProps = {
  kind: "body-s",
};

interface EntityUsageClusterProps {
  entityName: string;
  data: EntityParamUsageInfo;
  onItemClick: OnItemClickFn;
  isFirst?: boolean;
}

export const EntityUsageCluster = ({
  data,
  entityName,
  isFirst = false,
  onItemClick,
}: EntityUsageClusterProps) => (
  <ClusterContainer topSeparator={!isFirst}>
    <ClusterTitle>{`${entityName}.${data.paramName}`}</ClusterTitle>
    {data.usages.map((item) => {
      const key = item.title;
      const title = item.title;
      const icon = item.icon;
      const onClick = () => onItemClick(item.navigateToUsage);

      return (
        <EntityUsageItem
          icon={icon}
          key={key}
          onClick={onClick}
          title={title}
        />
      );
    })}
  </ClusterContainer>
);
