import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppState } from "@appsmith/reducers";

import { hideEntityUsageInfo } from "spread/store/slices/usageExplorer";

import { Popover } from "./components/Popover/Popover";
import { Header } from "./components/Header";
import { EntityUsageInfoResults } from "./components/Results";
import { useEntityUsageInfo } from "./useEntityUsageInfo";

const selectEntityInfo = (state: AppState) =>
  state.spread.usageExplorer.entityUsageInfo;

export function EntityUsageInfo() {
  const dispatch = useDispatch();
  const { entityId, entityName, entityType, show } =
    useSelector(selectEntityInfo);
  const entity = useSelector(
    (state) => entityName && state.evaluations.tree[entityName],
  );

  const hidePanel = useCallback(() => {
    dispatch(hideEntityUsageInfo());
  }, [dispatch]);

  const onItemClick = useCallback(
    (navigateToUsage: () => void) => {
      navigateToUsage();
      hidePanel();
    },
    [hidePanel],
  );

  const handleCloseClickEvent = useCallback(
    (e) => {
      e.stopPropagation();
      hidePanel();
    },
    [hidePanel],
  );

  const getEntityUsageInfo = useEntityUsageInfo();
  const data = useMemo(() => getEntityUsageInfo(entityName), [entityName]);

  if (!entityId || !entityName || !entityType || !entity) return null;

  return (
    <Popover
      entityId={entityId}
      handleCloseClickEvent={handleCloseClickEvent}
      show={show}
    >
      <Header entityName={entityName} onClose={handleCloseClickEvent} />
      <EntityUsageInfoResults
        data={data}
        entityName={entityName}
        entityType={entityType}
        onItemClick={onItemClick}
      />
    </Popover>
  );
}
