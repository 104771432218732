/**
 * Inspired by `src/pages/Editor/Explorer/Entity/EntityProperties.tsx
 */

import { useEffect, useRef } from "react";

/**
 * This constant defines the height of the entity element to which the info panel is attached.
 * It is used for calculations to determine the panel's position relative to the entity element
 * and to adjust the vertical offset of the panel.
 *
 * Equivalent to the `EntityHeight` constant of the `EntityProperties` component.
 */
const ENTITY_HEIGHT = 36; // Note: imo, with 40 it looks better, but would keep it as 36 for consistency

/**
 * This constant defines the height of the bottom bar (footer) on the screen.
 * It is used for calculations to avoid overlaying the info panel on the bottom bar,
 * ensuring that the panel is correctly placed within the visible area of the screen,
 * above the bottom bar.
 *
 * Equivalent to the `BottomBarHeight` constant of the `EntityProperties` component.
 */
const BOTTOM_BAR_HEIGHT = 34;

/**
 * Custom hook to adjust the size and position of a usage info panel based on the position of a related entity element.
 *
 * @param {string} entityId - The identifier of the entity element to base the panel position on.
 * @param {number} BindingContainerMaxHeight - The maximum height of the binding container.
 * @returns {React.RefObject<HTMLDivElement>} - A ref object to be attached to the usage info panel element.
 */
function useAdjustPopoverPosition(
  entityId: string,
  BindingContainerMaxHeight: number,
): React.RefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = document.getElementById(`entity-${entityId}`);
    const rect = element?.getBoundingClientRect();
    if (ref.current && rect) {
      const top = rect?.top;
      let bottom;
      if (
        top + BindingContainerMaxHeight >
        window.innerHeight - BOTTOM_BAR_HEIGHT
      ) {
        bottom = window.innerHeight - rect?.bottom - ENTITY_HEIGHT;
      }
      if (bottom !== undefined) {
        ref.current.style.bottom = `${bottom}px`;
        ref.current.style.top = "unset";
      } else {
        ref.current.style.top = `${top - ENTITY_HEIGHT}px`;
        ref.current.style.bottom = "unset";
      }
      ref.current.style.left = "100%";
    }
  }, [entityId, BindingContainerMaxHeight, BOTTOM_BAR_HEIGHT, ENTITY_HEIGHT]);

  return ref;
}

export default useAdjustPopoverPosition;
