import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { keyBy } from "lodash";

import type { AppState } from "@appsmith/reducers";
import { PluginType } from "entities/Action";
import { getActionConfig } from "pages/Editor/Explorer/Actions/helpers";
import WidgetIcon from "pages/Editor/Explorer/Widgets/WidgetIcon";
import {
  JsFileIconV2,
  jsFunctionIcon,
} from "pages/Editor/Explorer/ExplorerIcons";
import CustomWidget from "widgets/CustomWidget/widget";

export const useGetActionIconByActionId = () => {
  const actions = useSelector((state: AppState) => state.entities.actions);
  const actionGroups = useMemo(() => keyBy(actions, "config.id"), [actions]);

  const plugins = useSelector((state: AppState) => state.entities.plugins.list);
  const pluginGroups = useMemo(() => keyBy(plugins, "id"), [plugins]);

  const getActionIconByActionId = (
    actionId: string,
    pluginId: string,
  ): React.ReactNode => {
    const action = actionGroups[actionId];

    if (!action) return null;

    const { config } = action;

    const { pluginType } = config;
    return getActionConfig(pluginType)?.getIcon(
      config,
      pluginGroups[pluginId],
      pluginType === PluginType.API,
    );
  };

  return getActionIconByActionId;
};

const WidgetIconWrapper = styled.span`
  display: flex;
  svg {
    height: 14px;
    path {
      fill: #716e6e !important;
    }
  }
`;

const WIDGET_ICON_FALLBACK_TYPE = CustomWidget.type;

export const getWidgetIcon = (widgetType: string) => {
  return (
    <WidgetIconWrapper className="icon-wrapper">
      <WidgetIcon type={widgetType || WIDGET_ICON_FALLBACK_TYPE} />
    </WidgetIconWrapper>
  );
};

export const getJsObjectIcon = JsFileIconV2;

export const getJsFunctionIcon = () => jsFunctionIcon;
