import { jsCollectionIdURL } from "@appsmith/RouteBuilder";

import history from "utils/history";
import type { NavigationMethod } from "utils/history";

export const navigateToJsEntity = (
  pageId: string,
  collectionId: string,
  functionName?: string,
  navigationMethod?: NavigationMethod,
) => {
  history.push(
    jsCollectionIdURL({
      pageId,
      collectionId,
      functionName,
    }),
    { invokedBy: navigationMethod },
  );
};
